import { global } from ":global";
import type { ApiResponse } from "./_model";

export async function updateRecievedRequest(props: {
  item: ApiResponse.GetTeams["received_requests"][number];
  status: string;
}): Promise<ApiResponse.GetTeams> {
  const receiver_email = global.api.auth?.jwt?.email_address;
  const data = {
    id: props.item.id,
    receiver_email,
    requester_email: props.item.email,
    status: props.status,
    type: props.item.type,
  };
  return global.api.putAuth(`/teaming/accept-request/`, data).then((res) => {
    return res.data;
  });
}
